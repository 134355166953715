@import '../variables';

.backdrop.fade-in {
  opacity: 0.75;
  animation-name: backdropFadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes backdropFadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.25;
  }
}

.modal {
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
    animation-name: modalShow;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
  }
  .modal-header {
    @apply flex items-center justify-between p-4 border-b border-solid rounded-t-25;
    .modal-close {
      [data-direction='ltr'] & {
        @apply ml-auto;
      }
      [data-direction='rtl'] & {
        @apply mr-auto;
      }
    }
  }
  .modal-content {
    @apply border-0 relative flex flex-col w-full outline-none border-1 border-gray-300;
    @media (min-width: 768px) {
      @apply rounded-lg;
    }
  }
  .modal-footer {
    @apply items-center p-4 border-t border-solid rounded-b-25;
    @media (max-width: 768px) {
      @apply fixed bottom-0 w-full;
    }
  }
}

@keyframes modalShow {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@each $key, $value in $palettes {
  [data-background='#{$key}'] {
    .modal {
      .modal-header {
        @apply bg-white;
        //@apply #{map-get($value, "background-color")};
        @apply #{map-get($value, "text-color")};
        @apply #{map-get($value, "border-color")};
      }
      .modal-footer {
        @apply bg-white;
        //@apply #{map-get($value, "background-color")};
        @apply #{map-get($value, "text-color")};
        @apply #{map-get($value, "border-color")};
      }
      .modal-content {
        @apply bg-white;
        //@apply #{map-get($value, "background-color")};
        @apply #{map-get($value, "text-color")};
      }
    }
  }
}
