@import '../variables';

@each $key, $value in $palettes {
  [data-left-sidebar='#{$key}'] {
    .left-sidebar {
      //@apply #{map-get($value, "background-color")}
      @apply #{map-get($value, "text-color")};
      .user-card {
        img {
          @apply #{map-get($value, "border-color")};
        }
      }

      &.left-sidebar-2 {
        //@apply #{map-get($value, "background-color")}
        @apply #{map-get($value, "text-color")};
        ul {
          //@apply #{map-get($value, "background-color")}
          @apply #{map-get($value, "text-color")};
        }
      }

      li {
        @apply #{map-get($value, "text-color")};
        &:hover {
          @apply #{map-get($value, "highlight-text-color")};
        }

        button,
        a {
          @apply transition duration-150 ease-in-out;
          //@apply #{map-get($value, "background-color")}
          @apply #{map-get($value, "text-color")};
          @apply font-bold;
          .icon {
            svg {
              @apply #{map-get($value, "text-color")};
            }
          }

          &:hover {
            @apply #{map-get($value, "highlight-background-color")} #{map-get($value, "text-color")} bg-opacity-25;
            svg {
              //@apply animate-pulse;
            }

            .icon {
              svg {
                @apply #{map-get($value, "text-color")};
              }
            }
          }
        }

        &.is-open {
          //@apply #{map-get($value, "highlight-text-color")};
          > button,
          > a {
            @apply #{map-get($value, "highlight-background-color")};
            @apply #{map-get($value, "highlight-text-color")};
            svg {
              @apply #{map-get($value, "highlight-text-color")};
            }
          }
        }

        &.is-open:hover {
          > a {
            svg {
              @apply #{map-get($value, "highlight-text-color")};
            }
          }
        }
      }
    }
  }
}
