@import '../variables';

.tabs {
  .tab {
    @apply font-bold uppercase border-b-2;
    @apply text-xs p-2 px-4;
    &.tab-pill {
      @apply rounded-t-25 flex flex-col items-center justify-center w-24;
    }
  }
  .tab-content {
    @apply w-full py-2;
  }
  .tab-vertical {
    .tab-content {
      @apply w-full py-0 px-2;
    }
  }
}

@each $key, $value in $palettes {
  [data-background='#{$key}'] {
    .tabs {
      //@apply #{map-get($value, "background-color")};
      .tab {
        //@apply #{map-get($value, "background-color")};
        &:hover {
          //@apply rounded-t-lg;
          @apply #{map-get($value, "hover-background-color")};
        }
        &.tab-active {
          //@apply #{map-get($value, "highlight-background-color")};
          //@apply #{map-get($value, "highlight-text-color")};
        }
        &.tab-vertical {
          @apply border-none rounded-full;
          &:hover {
            @apply;
          }
          &.tab-active {
            @apply border-b-2 #{map-get($value, "highlight-border-color")};
            @apply #{map-get($value, "highlight-background-color")};
            @apply #{map-get($value, "highlight-text-color")};
          }
          &.tab-content {
            @apply w-full py-0;
          }
        }

        &.tab-underline {
          &:hover {
            @apply rounded-t-lg;

            //@apply #{map-get($value, "hover-background-color")};
            //@apply border-b-2 #{map-get($value, "highlight-border-color")};
          }
          &.tab-active {
            @apply rounded-t-lg;
            @apply border-b-2 #{map-get($value, "tabs-border-color")};
            //@apply #{map-get($value, "hover-background-color")};
            //@apply border-b-2 #{map-get($value, "highlight-border-color")};
            //@apply #{map-get($value, "highlight-background-color")};
            &:hover {
              @apply rounded-t-lg rounded-b-none;
            }
          }
        }
      }
      .tab-content {
        //@apply #{map-get($value, "background-color")};
      }
    }
  }
}
