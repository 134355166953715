@import '../variables';

.popover {
  @apply border-0 z-50 font-normal text-sm max-w-xs no-underline break-words rounded-lg shadow-lg hidden;
  &.popover-show {
    @apply block;
  }
  .popover-title {
    @apply font-semibold p-2 mb-0 border-b border-solid uppercase rounded-t-lg;
  }
  .popover-content {
    @apply p-2;
  }
}

@each $key, $value in $palettes {
  [data-background='#{$key}'] {
    .popover {
      @apply #{map-get($value, "background-color")};
      @apply #{map-get($value, "text-color")};
      @apply #{map-get($value, "border-color")};
    }
  }
}

.tooltip {
  @apply border-0 z-50 font-normal text-sm max-w-xs no-underline break-words rounded-lg border-1 border-gray-300 hidden;
  &.tooltip-show {
    @apply block;
  }
  .tooltip-content {
    @apply p-2;
  }
}

@each $key, $value in $palettes {
  [data-background='#{$key}'] {
    .tooltip {
      @apply #{map-get($value, "background-color")};
      @apply #{map-get($value, "text-color")};
      @apply #{map-get($value, "border-color")};
    }
  }
}
