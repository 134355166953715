@tailwind base;
@tailwind components;
@tailwind utilities;

[data-direction='ltr'] {
  * {
    direction: ltr;
  }

  .children-x-1 > * {
    margin-right: 0.25rem;
  }

  .children-x-2 > * {
    margin-right: 0.5rem;
  }

  .children-x-4 > * {
    margin-right: 1rem;
  }
}

[data-direction='rtl'] {
  * {
    direction: rtl;
  }

  .children-x-1 > * {
    margin-left: 0.25rem;
  }

  .children-x-2 > * {
    margin-left: 0.5rem;
  }

  .children-x-4 > * {
    margin-left: 1rem;
  }
}

/**
 * base styles
 */
h1 {
  @apply text-2xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

h4 {
  @apply text-lg;
}

h5 {
  @apply text-base;
}

h6 {
  @apply text-lg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-poppins;
}

code {
  @apply font-mono p-1 inline rounded font-bold;
}

pre {
  @apply font-mono p-2 block rounded font-bold;
}

html {
  @apply font-sans;
}

body {
  @apply font-sans antialiased text-sm;
}

.w-96 {
  width: 24rem;
}

.link {
  @apply text-brand-base;
}

.link_underline {
  @apply text-brand-base underline;
}

.raised {
  @apply shadow;
}

/**
 * hide scrollbars
 */
::-webkit-scrollbar {
  //display: none;
  //width: 0 !important;
}

.disable-scrollbars {
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none; /* IE 10+ */
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.showscrollbar::-webkit-scrollbar {
  display: initial;
}

/* Hide scrollbar for IE, Edge and Firefox */
.showscrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

/**
 * required to avoid flexbox issues in nextjs apps
 */
#__next {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

.homepage {
  [data-direction='ltr'] & {
    .homepage-navbar-actions {
      @apply ml-auto;
    }
  }

  [data-direction='rtl'] & {
    .homepage-navbar-actions {
      @apply mr-auto;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

.body,
input,
textarea,
keygen,
select,
button,
div,
span,
label,
h1 {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

//.wrapper { overscroll-behavior: none }

@media (min-width: 768px) {
  .md\:h-600 {
    height: 600px;
  }
  .md\:h-700 {
    height: 700px;
  }
  .md\:h-none {
    height: px;
  }
}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}

.react-date-picker,
.react-daterange-picker {
  @apply rounded-lg;
}

.react-date-picker .react-date-picker__wrapper,
.react-daterange-picker .react-daterange-picker__wrapper {
  border: none;
}

.react-date-picker .react-date-picker__button {
  width: 16px;
  height: 16px;
  padding-top: 0px;
  margin-right: 8px;
}

//.react-date-picker-border:focus-within{
//  @apply border-b border-t border-l border-r border-red-500;
//}

.react-select__menu {
  @apply overflow-hidden;
}

.react-select__input {
  @apply text-md;
  @media (min-width: 768px) {
    @apply text-sm;
  }
}

.react-date-picker-border input:focus {
  @apply border-none;
}

.react-select,
.react-select__control,
.react-select__control--is-focused {
  @apply border-none;
}

.react-select > div,
.react-select > div:hover {
  @apply border-b border-t border-l border-r rounded-lg border-gray-300 text-gray-700 shadow-none;
  min-height: 39px;
}

.react-select:focus-within > div {
  @apply border-b border-t border-l border-r border-brand-light rounded-lg;
}

.react-select__indicators .react-select__indicator {
  padding: 7px;
}

.react-select.isWorking .react-select__indicators {
  @apply invisible;
}

//.react-select .react-select__menu {
//  @apply w-screen h-screen fixed top-0 left-0 right-0 bottom-0;
//}

.react-select .react-select__multi-value__label .rs_val,
.react-select .react-select__single-value .rs_val {
  display: none;
}

.user_panel {
}

.user_text {
}

.ellipsis-parent {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.ellipsis-child {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  @apply overflow-hidden whitespace-no-wrap;
}

.parent-show:hover {
  .child-hide {
    @apply visible;
  }
}

.parent-show {
  .child-hide {
    @apply invisible;
  }
}

.campaign-card:hover {
  .campaign-card-go {
    @apply invisible;
  }
}

.campaign-card:hover {
  .campaign-card-go {
    @apply text-brand-light visible;
  }
}

.form-input {
  @apply text-md py-1-4;
  @media (min-width: 768px) {
    @apply text-sm py-2;
  }
}

//For popup
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  @apply border-1 border-gray-300;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip {
  z-index: 100;
  @apply bg-blue-100 font-medium border-brand-light;
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -5px;
  @apply border-1 border-brand-light border-l-0 border-t-0;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -5px;
  @apply border-1 border-brand-light;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

#tooltip[data-popper-placement^='top'] > #arrow::before {
  bottom: -1px;
  @apply border-l-0 border-t-0 border-brand-light;
}

#tooltip[data-popper-placement^='bottom'] > #arrow::before {
  top: -1px;
  @apply border-b-0 border-r-0 border-brand-light;
}

#tooltip[data-popper-placement^='left'] > #arrow::before {
  right: -4px border-brand-light;
}

#tooltip[data-popper-placement^='right'] > #arrow::before {
  left: -4px border-brand-light;
}

button:focus {
  outline: 2px solid blue;
}

//button:focus::after{
//  content: '' ;
//  display: block ;
//  position: absolute ;
//  top: -2px ;
//  bottom: -2px ;
//  left: -2px ;
//  right: -2px ;
//  border: 2px solid blue ;
//  @apply rounded-25;
//}

@media print {
  header, footer {
    width: 100%; height: .5in;
  }
  header {
    position: absolute;
    top: 0;
  }
  header, footer {
    position: fixed;
  }
  footer {
    bottom: 0;
  }

  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 300px;
  }
}

.Toastify__toast-body {
  @apply text-black;
}

.MuiInputBase-root input {
  color: #4a5552;
  @apply text-md;
}

.MUI-datepicker {
  @apply bg-gray-100 rounded-10px mt-1 px-3 text-sm;
  padding-top: 4px;
  padding-bottom: 3px;
}

.MuiInputBase-input {
  @apply text-center cursor-pointer;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

.list-initial {
  list-style: inside !important;
}

.drtl {
  direction: rtl;
}

.slick-slide {
  outline: 0;

  div {
    outline: 0;
  }
}

.slick-slide:focus {
  outline: 0;
}

.slick-prev {
  left: -20px !important
}

.slick-next {
  right: -15px !important
}

.slick-next:before, .slick-prev:before {
  color: #000 !important;
}

.slick-next:before {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slick-prev:before {
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.slick-up, .slick-down {
  font-size: 20px;
  z-index: 2;
}

.slick-up:before {
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slick-down:before {
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.slick-next:before, .slick-prev:before, .slick-up:before, .slick-down:before {
  content: '' !important;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-top: 0.2rem solid #333;
  border-right: 0.2rem solid #333;
}

.stacks-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  > div:first-child {
    position: absolute;
    left: 10px;
    background: #000;
    border-radius: 5px;
    height: 24px;
    width: 15px;
    z-index: 2;
  }

  > div:nth-child(2) {
    position: absolute;
    background: #000;
    height: 18px;
    width: 2.5px;
    left: 27px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  > div:last-child {
    position: absolute;
    background: #000;
    height: 13px;
    width: 2.5px;
    left: 32px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

// Custom Scroll starts
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: lightgray #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

// Custom Scroll ends

.font-border{
  text-shadow: -1px 1px 0 #000,
  1px 1px 0 #000,
  1px -1px 0 #000,
  -1px -1px 0 #000;
}

.arc{
  border-width: 12px;
  border-style: solid;
  display: inline-block;
  min-width: 10em;
  min-height: 10em;
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 90%;
}

.arc:hover{
  opacity: 0.75;
}

.arc-good{
  border-color: #6AAA6B;
  border-bottom-color: transparent;
}
.arc-average{
  border-color: #DCB357;
  border-bottom-color: transparent;
}
.arc-low{
  border-color: #DB7D51;
  border-bottom-color: transparent;
}

.border-b-solid{
  border-bottom-style: solid;
}

/* Circle Chart */
.single-chart {
  justify-content: space-around ;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}


svg path:hover {
  filter: opacity(70%);
  transition: all ease 0.3s;
}

svg path {
  transition: all ease 0.3s;
}

.text-white .Toastify__toast-body,
.Toastify__toast--success .Toastify__toast-body,
.Toastify__toast--info .Toastify__toast-body,
.Toastify__toast--error .Toastify__toast-body{
  color: #FFFFFF;
}

.customShadow{
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.12);
}

.customShadow:hover{
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.12);
}

.customInnerShadow{
  box-shadow: inset 4px 7px 20px 8px rgba(0, 98, 255, 0.1);
  border-radius: 20px;
}

.customDropShadowNoHover{
  //filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25));
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  //border: 1px solid green;
  border-radius: 10px;
}

.customSoftDropShadow{
  box-shadow: 4px 4px 50px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
.customSoftDropShadow:hover{
  box-shadow: 4px 4px 25px 10px rgba(0, 0, 0, 0.15);
}

.customDropShadow{
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25));
}

.customDropShadow:hover{
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
}

.blueBold{
  @apply font-bold text-2xl text-brand-base;
  //filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
  //text-shadow: -1px 1px 0 #0737A3,
  //1px 1px 0 #0737A3,
  //1px -1px 0 #0737A3,
  //-1px -1px 0 #0737A3;
  -webkit-text-stroke: 1px #0737A3;
  //text-shadow: -1px -1px 0 #0737A3,-1px 0px 0 #0737A3,-1px 1px 0 #0737A3,0px -1px 0 #0737A3,0px 0px 0 #0737A3,0px 1px 0 #0737A3,1px -1px 0 #0737A3,1px 0px 0 #0737A3,1px 1px 0 #0737A3;
  letter-spacing: 1px;
}

.backdrop-blur{
  backdrop-filter: blur(8px);
}

.grayscale{
  filter: grayscale(100%);
}

.pagebreak {
  clear: both;
  page-break-after: always;
}

.billu{
  background-color: #4299e1;
}

.boxBlackShadow:hover{
  box-shadow: 3px 3px 0 0 #0a0b1e;
}