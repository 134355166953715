.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 40px;
  border-radius: 2px;
  color: #fff;
  background-color: #edf2f7;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}
.notification .title {
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
  margin: 0 0 5px 0;
}
.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -7px;
  display: block;
  font-family: 'simple-line-icons';
  width: 14px;
  height: 14px;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
}

.notification-info {
  background-color: #4299e1;
}
.notification-info:before {
  content: '\e08b';
}

.notification-success {
  background-color: #48bb78;
}
.notification-success:before {
  content: '\e080';
}

.notification-warning {
  background-color: #ecc94b;
}
.notification-warning:before {
  content: '\e617';
}

.notification-error {
  background-color: #f56565;
}
.notification-error:before {
  content: '\e082';
}
