@import '../variables';

@each $key, $value in $palettes {
  [data-navbar='#{$key}'] {
    .navbar {
      @apply #{map-get($value, "background-color")};
      @apply #{map-get($value, "text-color")};
      input {
        @apply #{map-get($value, "hover-background-color")};
      }
      .navbar-dropdown {
        @apply #{map-get($value, "background-color")};
        @apply #{map-get($value, "text-color")};
        &-title {
          @apply #{map-get($value, "highlight-background-color")};
          @apply #{map-get($value, "highlight-text-color")};
        }
      }
    }
  }
}
