.quill{
  flex:1;
  margin-bottom: 42px;
}

.ql-toolbar{
  border-color: #e2e8f0 !important;
  border-radius: 0.5rem;
}

.ql-container{
  border-color: #e2e8f0 !important;
  border-radius: 0.5rem;
}