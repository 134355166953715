[data-direction='ltr'] {
  &[data-layout='centered'] {
    .navbar {
      @apply z-10 fixed top-0 left-0 right-0 p-0 w-full h-16 flex shadow;
      //@apply pl-64;
    }
    .left-sidebar {
      @apply shadow fixed top-0 left-0 bottom-0 h-full z-10 w-64 transition-all duration-500;
      li {
        @apply mx-8 transition-all duration-500;
        a {
          @apply px-4 transition-all duration-500;
        }
      }
      .icon {
        @apply m-auto;
      }
      .item {
        @apply ml-2 opacity-100 w-auto transition-all duration-500;
      }
      //.logo_panel{
      //  @apply  w-64 opacity-0 transition-all duration-300;
      //}
      .user_panel {
        @apply w-64 transition-all duration-500;
        img {
          @apply h-20 w-20 transition-all duration-500;
        }
        p {
          @apply opacity-100 transition-all duration-500;
        }
      }
    }
    .main {
      @apply pl-64 transition-all duration-500;
    }
    &[data-collapsed='true'] {
      .left-sidebar {
        @media (min-width: 768px) {
          @apply w-20 transition-all duration-500;
          //@apply text-center transition-all delay-500;
        }
        li {
          @apply mx-4 transition-all duration-500;
          a {
            @apply px-3-5 transition-all duration-500;
          }
        }
        .item {
          @apply opacity-0 w-0 transition-all duration-500;
        }
        //.logo_panel{
        //  @apply w-20 opacity-100 transition-all duration-300;
        //}
        .user_panel {
          @apply w-20 transition-all duration-500;
          img {
            @apply h-12 w-12 transition-all duration-500;
          }
          p {
            @apply opacity-0 transition-all duration-500;
          }
        }
        @apply w-0 overflow-hidden;
        //&.left-sidebar-2 {
        //  @apply w-0 overflow-hidden;
        //}
      }
      .navbar,
      .main {
        @media (min-width: 768px) {
          @apply pl-20;
        }
        @apply pl-0;
      }
    }
  }
}
